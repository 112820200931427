/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {useMemo} from 'react';

import {get} from 'lodash-es';

import {ITboEvent, ITboContainerArea} from '../../../models';

import {useEntity} from '../../../hooks/useEntity';
import {useLocale} from '../../../hooks/useLocale';

export function TboContainersField(props: {rowData: ITboEvent}) {
  const {rowData} = props;
  const {containers} = rowData;

  const locale = useLocale();
  const id = useMemo(() => get(rowData, 'tboContainerAreaId') ?? '', [rowData]);
  const [loading, _, entity] = useEntity<ITboContainerArea>('TboContainerArea', id);

  const currentContainer = Array.isArray(entity?.containers)
    ? entity?.containers.find(({typeName}) => typeName === containers?.type)
    : null;

  if (loading) return null;

  return currentContainer && containers?.count ? (
    <span css={containers.count > currentContainer.maxCount ? highlight : undefined}>
      <p>{`${locale.containerTypes[containers!.type]}: `}</p>
      <span>
        {' '}
        {`${containers?.count} ${locale.from} ${
          [null, undefined, ''].includes(currentContainer.maxCount) ? '-' : currentContainer.maxCount
        } ${locale.allowed}`}
      </span>
    </span>
  ) : (
    <span> - </span>
  );
}

const highlight = css`
  color: var(--error-color);
`;
