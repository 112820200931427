/** @jsx jsx **/
import {css, jsx} from '@emotion/react';
import {applyJoin} from '../../utils/applyJoin';
import {useEntities} from '../../hooks/useEntity';
import {useMemo} from 'react';
import {get} from 'lodash-es';
import {RefArrayField as RefArrayFieldProps} from '../../models';

const $textWrap = css`
  overflow-wrap: break-word;
`;

const isNotNull = (v: any): v is NonNullable<any> => v !== null;

type Props = {
  rowData: any;
  cell: RefArrayFieldProps;
};

export const RefArrayField = (props: Props) => {
  const {rowData, cell} = props;
  const {idsField, typeStatic, join = {fields: ['title']}} = cell;
  const ids = useMemo(() => get(rowData, idsField) as string[], [rowData, idsField]);
  const [, entities] = useEntities(typeStatic, ids);

  const text: string = useMemo(() => {
    if (ids?.length === 0) return '';

    return ids
      .map((id) => entities.get(id) ?? null)
      .filter(isNotNull)
      .map((entity) => applyJoin(entity, join))
      .join('; ');
  }, [entities, ids, join]);

  return text.length === 0 ? (
    <span>{'-'}</span>
  ) : (
    <span css={[$refArrayCss, $textWrap]}>[ {text.length > 120 ? `${text.slice(0, 120)}...` : text} ]</span>
  );
};

const $refArrayCss = css`
  & {
    font-size: calc(12rem / var(--bfs));
  }
`;
