import {useWidgetProps} from '../../hooks/useWidgetProps';
import {EventsTypes, EventType} from '../../models';

type EventDataInfo = {
  event: EventsTypes;
  eventType: EventType;
};

const useEventDataInfo: () => EventDataInfo = () => {
  const {widgetProps: {props: {eventName} = {}}} = useWidgetProps();
  const event = eventName || EventsTypes.AssignmentEvent;
  return {
    event,
    eventType: EventType[event]
  };
};

export {useEventDataInfo};
