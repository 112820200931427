import {LibApiRepository} from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository';
import {IAssignmentEvent, UnifiedEvent, isAssignmentEvent} from '../../models';
import {CubeMixin, TUnionRepo} from '@netvision/lib-api-repo';

export const doUseExternalFile = (event: UnifiedEvent): event is IAssignmentEvent =>
  isAssignmentEvent(event) ? !['Metadata', undefined].includes(event?.assignment?.parameters?.syncMode) : true;

const isWithCubeJSMixins = (api: TUnionRepo): api is LibApiRepository & CubeMixin => 'cubeGetEntities' in api;

const getStreamDataByLibApi = async (api: TUnionRepo, id: string) => {
  const {results} = await api.getEntitiesList<{
    dvrTimelines: [
      {
        start: number;
        duration: number;
        end: number;
      }
    ];
  }>({
    limiter: {
      type: 'Stream'
    },
    filter: {
      attrs: 'hlsStreamUrl,title,streamType,dvrTimelines,id',
      q: [
        {
          key: 'cameraId',
          value: id,
          operator: '=='
        }
      ]
    }
  });

  return results;
};

const getStreamDataByCubeJs = async (api: CubeMixin & TUnionRepo, cameraId: string) => {
  if (!('getEntitiesWithGlobalBatch' in api)) {
    console.error('Method getEntitiesWithGlobalBatch not implemented');
    return [];
  }

  const {results} = await api?.cubeGetEntities<{id: string}>({
    dimensions: ['Stream.id'],
    filters: [
      {
        member: 'Stream.cameraId',
        operator: 'equals',
        values: [cameraId]
      }
    ]
  });

  const promises = results.map(
    ({id}) =>
      api.getEntitiesWithGlobalBatch(
        {type: 'Stream', id},
        ['hlsStreamUrl', 'title', 'streamType', 'dvrTimelines', 'id'],
        'event-details'
      ) as unknown as {
        dvrTimelines: [
          {
            start: number;
            duration: number;
            end: number;
          }
        ];
      }
  );

  return await Promise.all(promises);
};

export const isArchiveAvailable = async (api: TUnionRepo, {id, timestamp}: {id: string; timestamp: number}) => {
  const results = isWithCubeJSMixins(api)
    ? await getStreamDataByCubeJs(api, id)
    : await getStreamDataByLibApi(api, id);

  if (!Array.isArray(results) && !results[0]) return;

  const eventTimestamp = timestamp / 1000;
  return results.some(({dvrTimelines}) =>
    dvrTimelines?.some(({end, start}) => eventTimestamp > start && eventTimestamp < end)
  );
};
