/** @jsx jsx **/
import {css, jsx} from '@emotion/react';
import React from 'react';
import {ObjectField as ObjectFieldProps} from '../../models';
import {applyJoin} from '../../utils/applyJoin';
import {JsonField} from '../ui/JsonFIeld';

const $textWrap = css`
  overflow-wrap: break-word;
`;

export const ObjectField = ({object, cell}: {object: object; cell: ObjectFieldProps}) => {
  if (!object === null) return null;
  const {join} = cell;

  if (join) {
    const text = applyJoin(object, join);
    return !text ? null : <span css={$textWrap}>{text}</span>;
  }

  return <JsonField object={object} />;
};
