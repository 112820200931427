import React from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';

export const DialogInformer: React.FC<{height?: number | string; text?: string}> = (props) => {
  const {height, text} = props;
  return (
    <div css={$dialogInformer} style={{height}}>
      <span>{text}</span>
      {props.children}
    </div>
  );
};

const $dialogInformer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg);
  color: var(--text-color);
`;
