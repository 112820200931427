/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {createIconSizesCss} from './createIconSizesCss';

const strokeTextColorCss = css`
  & {
    stroke: var(--text-color-secondary);
  }
`;

const sizesCss = createIconSizesCss(20, 20);

export const TimeDecay = () => {
  return (
    <div css={sizesCss}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          css={strokeTextColorCss}
          d="M9.83471 18C14.253 18 17.8347 14.4183 17.8347 10C17.8347 5.58172 14.253 2 9.83471 2C6.87358 2 4.28821 3.60879 2.90497 6"
          stroke="#959DA8"
          strokeWidth="2"
        />
        <path
          css={strokeTextColorCss}
          d="M2.14282 12.0542C3.26166 16.3086 7.62752 18.8531 11.8942 17.7375C16.161 16.6219 18.7128 12.2686 17.594 8.01414C16.8439 5.162 14.6343 3.07928 11.9738 2.35168"
          stroke="#959DA8"
          strokeWidth="1.8"
          strokeLinecap="square"
          strokeDasharray="2 4"
        />
        <path
          css={strokeTextColorCss}
          d="M9.80657 4.99365L9.80657 11.006L13.2678 11.006"
          stroke="#959DA8"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};
