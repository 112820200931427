import React, {useMemo} from 'react';
import {get} from 'lodash-es';
import {useEntity} from '../../hooks/useEntity';
import {applyJoin} from '../../utils/applyJoin';
import {getFieldByType} from '../../helpers/getFieldByType';
import {RefTransformField as RefTransformFieldProps} from '../../models';

type Props<T extends {}> = {
  rowData: T;
  description: RefTransformFieldProps;
};

const replacePattern = '{{id}}';
const transformId = (value: string, template: string) => template.replace(replacePattern, value);

export const RefTransformField = (props: Props<{}>) => {
  const {rowData, description} = props;
  const {idField, typeStatic, transformTemplate, join = {fields: ['title']}} = description;
  const id = useMemo(() => {
    const value = get(rowData, idField);
    return transformId(value, transformTemplate);
  }, [idField, rowData, transformTemplate]);

  const [loading, , entity] = useEntity(typeStatic, id);
  const text = useMemo(() => (entity ? applyJoin(entity, join) : ''), [entity, join]);

  if (loading) return null;
  return (
    !text
      ? <span>{'-'}</span>
      : <span>{entity && getFieldByType({
          rowData: entity,
          cell: description.scheme,
          field: idField
        })}</span>
  )
};
