import {useEffect, useMemo, useState} from 'react';
import {EventsTypes, EventMetaData} from '../../models';
import {MetaDataType} from '../../Root';
import {useApiRepository} from './useApiRepository';

export const useGetMetaData = (type: string, lang: string): MetaDataType | null => {
  const [metaData, setMetaData] = useState<EventMetaData>(null!);
  const { api } = useApiRepository()
  
  useEffect(() => {
    let aborted = false;

    api.getEntitiesList<MetaStructure>({
      limiter: {
        id: `EntityTypeMetadata:${type}`,
        type: "EntityTypeMetadata"
      },
      filter: {
        attrs: 'journal,locales'
      }
    })
      .then(({ results }) => {
        if (aborted || !results) return;
        const metadata = results[0]
        setMetaData({
          $entityType: type,
          $locale: metadata.locales[lang],
          viewTable: metadata.journal
        })
      })

    return () => {
      aborted = true;
    };
  }, [lang, type]);

  return useMemo(() => normalizedMetaData(metaData), [metaData]);
};

const normalizedMetaData = (metaData: EventMetaData): ReturnType<typeof useGetMetaData> => {
  if (!metaData) return null;
  return {
    eventType: metaData.$entityType as EventsTypes,
    locale: metaData.$locale,
    tableSetting: metaData.viewTable
  };
};

type MetaStructure = Record<string, any>
