import React, {useEffect, useState} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Button} from 'primereact/button';
import {useWidgetProps} from '../../../../hooks/useWidgetProps';
import {UnifiedEvent} from '../../../../models';

const $prevNext = css`
  > button {
    margin-right: var(--spacer-xs);
  }

  > button:last-child {
    margin-right: 0;
  }
`;

export const SlideEvent = ({newEvent}: {newEvent: UnifiedEvent}) => {
  const {widgetProps: {props: {onNext, onPrev, canNext, canPrev} = {}}} = useWidgetProps();
  const [loading, setLoading] = useState(false);
  const slideEvent = (slideCallback: () => void) => {
    setLoading(true);
    slideCallback();
  }

  useEffect(() => {
    setLoading(false);
  }, [newEvent, canNext, canPrev]);

  const isVisibleSlideButton = onPrev && onNext && (canNext || canPrev)

  return (
    <>
      {isVisibleSlideButton && (
        <div css={$prevNext}>
          <Button
            disabled={loading || !canPrev}
            icon={'mdi mdi-24px mdi-chevron-left'}
            className={'p-button-sm p-button-rounded p-button-outlined p-button-secondary'}
            onClick={() => slideEvent(onPrev)}
          />
          <Button
            disabled={loading || !canNext}
            icon={'mdi mdi-24px mdi-chevron-right'}
            className={'p-button-sm p-button-rounded p-button-outlined p-button-secondary'}
            onClick={() => slideEvent(onNext)}
          />
        </div>
      )}
    </>
  );
};
