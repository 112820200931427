import React, {FC} from 'react';
/** @jsx jsx */
import {jsx} from '@emotion/react';

import {spinnerStyle, buttonCss} from '../style';
import {Button} from 'primereact/button';
import {useLocale} from '../../../hooks/useLocale';

export const Spinner = () => {
  return (
    <div css={spinnerStyle}>
      <i className={'pi pi-spin pi-spinner'} />
    </div>
  );
};

export const ToArchiveButton: FC<{onClick: () => void}> = ({onClick}) => {
  const locale = useLocale();
  return <Button css={buttonCss} label={locale.archiveLabel} iconPos={'left'} icon={'pi pi-play'} onClick={onClick} />;
};
