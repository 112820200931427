/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';
import {TextFieldIcon} from '../../models';

// language=SCSS
const style = css`
  & {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--text-color);

    i {
      color: var(--text-color);
      height: calc(24rem / var(--bfs));
      width: calc(24rem / var(--bfs));
      font-size: calc(24rem / var(--bfs));
      margin-right: calc(15rem / var(--bfs));
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

type DataWithIcon = {
  icon: {
    iconClass: string;
    color: string;
  };
  title: string;
};

type Props<T> = {
  data: T;
  cell: TextFieldIcon;
};

export const TextIconField = (props: Props<DataWithIcon>) => {
  const {data} = props;
  const {
    icon: {color, iconClass},
    title
  } = data;
  return (
    <div css={style}>
      <i className={iconClass} style={{color: `var(${color})`}} />
      {title}
    </div>
  );
};
