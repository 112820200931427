import {useEffect, useMemo, useState} from 'react';
import {getPermissionsByIdsMap} from '@netvision/lib-api-gateway';

let cacheIds: Map<string, string[]> = new Map();
export const useGetPermissionsById = (id: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [data, setData] = useState<string[]>();

  useEffect(() => {
    let abortComponent = false;

    const getPermissions = async () => {
      try {
        setLoading(true);
        const data = (await getPermissionsByIdsMap([id])) as unknown as Map<string, {key: string; value: string[]}>;
        if (!data || abortComponent) return;
        const [_, valueMap] = data.entries().next().value
        const value = valueMap || [''];
        cacheIds?.set(id, value);
        setData(value);
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (abortComponent) return;
    if (cacheIds.has(id)) {
      setData(cacheIds.get(id));
      return;
    }
    getPermissions();

    return () => {
      abortComponent = true;
    };
  }, [data, id]);

  return useMemo(
    () => ({
      permissions: data,
      loading,
      error
    }),
    [data, error, loading]
  );
};
