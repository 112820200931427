import {UnifiedEvent, isAssignmentEvent} from '../models/';
import {isVehicleTransitEvent} from '../models/isVehicleTransitEvent';
import {defaultsDeep} from 'lodash-es';

export interface OverlayParams {
  /**
   * Element that contains overlay.
   */
  overlayContainer: HTMLElement;
  /**
   * This element is used to calculate sizes.
   * ResizeObserver tracks changes
   */
  overlayBase: HTMLElement;
  /**
   * This sizes are used as a maximum value for coordinates.
   * Also this is used to to place canvas properly,
   * in case when aspect ratio of video or image does not correspond to it's container aspect ratio (black borders).
   */
  naturalHeight: number;
  naturalWidth: number;
}

const defaultStyles = {
  area: {
    fillColor: 'rgba(50, 50, 130, 0.1)',
    strokeColor: 'rgba(50, 50, 130, 1)'
  },
  line: {
    strokeColor: 'rgba(50, 50, 130, 1)'
  },
  fallback: {
    fillColor: 'rgba(230, 50, 50, 0.1)',
    strokeColor: 'rgba(230, 50, 50, 1)'
  },
  plate: {
    strokeColor: 'rgba(230, 50, 50, 1)'
  },
  vehicle: {
    strokeColor: 'rgba(230, 50, 50, 1)'
  }
};

export type ICanvasOverrideStyles = Partial<typeof defaultStyles>;

const mergeStyles = (styles: ICanvasOverrideStyles): typeof defaultStyles => {
  return defaultsDeep(styles, defaultStyles);
};

export function getCanvasInitState(
  event: UnifiedEvent,
  styles: ICanvasOverrideStyles,
  naturalSize: {x: number; y: number}
) {
  const mergedStyles = mergeStyles(styles);
  const layerId = isAssignmentEvent(event) ? event.assignmentId : event.id;

  const polygons = [];
  const lines = [];
  // areas
  // zone

  if (isAssignmentEvent(event) && event.assignment?.parameters.points) {
    const {assignment} = event;
    polygons.push({
      id: layerId,
      editable: false,
      name: buildPathLabel(assignment.title, assignment.assignmentTypeTitle),
      style: mergedStyles.area,
      points: assignment.parameters.points?.map(([x, y]: any, index: any) => ({x, y, index}))
    });
  }

  // line
  if (isAssignmentEvent(event) && event.assignment?.parameters.a && event.assignment.parameters.b) {
    const {assignment} = event;
    lines.push({
      id: layerId,
      editable: false,
      name: buildPathLabel(assignment.title, assignment.assignmentTypeTitle),
      style: mergedStyles.line,
      start: {index: 0, x: assignment.parameters.a?.[0], y: assignment.parameters.a?.[1]},
      end: {index: 1, x: assignment.parameters?.b?.[0], y: assignment.parameters.b?.[1]},
      directed: assignment.parameters.areaType === 'Directed'
    });
  }

  // event zone
  const {bbox} = event;
  if (bbox !== undefined) {
    const [x, y, width, height] = bbox;
    polygons.push({
      id: event.id,
      editable: false,
      style: mergedStyles.fallback,
      points: [
        {x, y},
        {x: x + width, y},
        {x: x + width, y: y + height},
        {x: x, y: y + height}
      ]
        .map((val) => {
          val.x = val.x * naturalSize.x;
          val.y = val.y * naturalSize.y;
          return val;
        })
        .map((val, index) => Object.assign(val, {index}))
    });
  }

  const {tracks} = event as {tracks?: Array<{bbox: [number, number, number, number]; timestamp: number}>};
  
  if (tracks !== undefined && Array.isArray(tracks) && tracks.length > 0) {
    const [x, y, width, height] = tracks[0].bbox;
    polygons.push({
      id: event.id,
      editable: false,
      style: mergedStyles.fallback,
      points: [
        {x, y},
        {x: x + width, y},
        {x: x + width, y: y + height},
        {x: x, y: y + height}
      ].map((val, index) => Object.assign(val, {index}))
    });
  }

  if (isAssignmentEvent(event) && isVehicleTransitEvent(event) && event.bboxes) {
    const {bboxes} = event;
    polygons.push(
      ...bboxes.map((bbox) => {
        const name = bbox.objectType === 'plate' ? event.vehicle.plate : undefined;
        const style = mergedStyles[bbox.objectType] || mergedStyles.fallback;
        return {
          id: event.id + bbox.objectType,
          editable: false,
          name,
          namePosition: 'bottom',
          style,
          points: createPointsFromBBox({bbox: bbox.bbox, naturalSize, pointsType: bbox.pointsType})
        };
      })
    );
  }

  return {
    itemStore: {
      polygonStore: {
        items: polygons,
        editableLimit: 0
      },
      lineStore: {
        items: lines,
        editableLimit: 0
      }
    }
  };
}

function createPolygonData(data: {
  bbox: [number, number, number, number];
  id: string;
  editable: boolean;
  naturalSize: {x: number; y: number};
  pointsType: string;
  name: string;
  namePosition: string;
}) {
  const {bbox, editable, id, naturalSize, pointsType, name, namePosition} = data;
  return {
    id,
    editable,
    name,
    namePosition,
    points: createPointsFromBBox({bbox, naturalSize, pointsType})
  };
}

function createPointsFromBBox(data: {
  bbox: [number, number, number, number];
  naturalSize: {x: number; y: number};
  pointsType: string;
}) {
  const {bbox, naturalSize, pointsType} = data;
  const [x, y, width, height] = bbox;

  let points = [
    {x, y},
    {x: x + width, y},
    {x: x + width, y: y + height},
    {x: x, y: y + height}
  ];

  if (pointsType !== 'absolute') {
    points = points.map((val) => {
      val.x = val.x * naturalSize.x;
      val.y = val.y * naturalSize.y;
      return val;
    });
  }

  return points.map((val, index) => Object.assign(val, {index}));
}

function buildPathLabel(name?: string, type?: string) {
  const start = name ? `${name}\n` : '';
  const end = type ? type : '';
  return `${start}${end}`;
}
