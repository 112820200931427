import {
  DateTimeField,
  EnumField,
  ModalField,
  ObjectField,
  RefArrayField,
  RefField,
  RefTransformField,
  ScheduleField,
  TboContainerField,
  TextField,
  TextFieldIcon
} from './fields';

import {FieldsType} from '.';

// Fields validators
export const isTextField = (cell: FieldsType): cell is TextField => ['text', 'id', 'copy'].includes(cell.type);
export const isTextIconField = (cell: FieldsType): cell is TextFieldIcon => cell.type === 'textIcon';
export const isDateTimeField = (cell: FieldsType): cell is DateTimeField => ['dateTime', 'date'].includes(cell.type);
export const isRefField = (cell: FieldsType): cell is RefField => cell.type === 'ref';
export const isRefArrayField = (cell: FieldsType): cell is RefArrayField => cell.type === 'refArray';
export const isRefTransformField = (cell: FieldsType): cell is RefTransformField => cell.type === 'refTransform';
export const isEnumField = (cell: FieldsType): cell is EnumField => cell.type === 'enum';
export const isObjectField = (cell: FieldsType): cell is ObjectField => cell.type === 'object';
export const isModalField = (cell: FieldsType): cell is ModalField => cell.type === 'modal';
export const isScheduleField = (cell: FieldsType): cell is ScheduleField => cell.type === 'schedule';
export const isTboContainerField = (cell: FieldsType): cell is TboContainerField => cell.type === 'tboContainer';
