/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, ReactElement, useCallback, useRef, useState} from 'react';
import {useLocale} from '../../../hooks/useLocale';
import {Button} from 'primereact/button';
import {AssignmentEventStatus} from '../../../models';
import {commandsButtonCss} from './styled';

interface CommandButtonProps {
  status: string;
  label: string;
  color: string;
  icon: (disabled: boolean) => ReactElement;
  currentStatus?: string;
  onClick: (status: string) => Promise<void>;
}

const pressThreshold = 1000;

export const CommandButton: FC<CommandButtonProps> = ({currentStatus, status, label, icon, color, onClick}) => {
  const locale = useLocale();
  const progressRef = useRef<HTMLDivElement | null>(null);
  const [onHold, setOnHold] = useState(false);
  const onPointerDown = useCallback(() => {
    const node = progressRef.current;
    if (!node) {
      return;
    }

    setOnHold(true);

    node.style.removeProperty('background');
    node.style.height = '100%';
    node.style.width = '100%';

    const cleanUp = () => {
      clearTimeout(id);
      setOnHold(false);
      node.style.removeProperty('width');
      node.style.removeProperty('height');
    };

    window.addEventListener('pointerup', cleanUp, {
      capture: true,
      once: true
    });

    const id = setTimeout(() => {
      window.removeEventListener('pointerup', cleanUp, {
        capture: true,
        // @ts-ignore
        once: true
      });
      onClick(status).then(() => {
        node.style.setProperty('background', 'transparent');
        cleanUp();
      });
    }, pressThreshold);
  }, [onClick, status]);
  return (
    <Button
      data-cy={ status }
      disabled={currentStatus !== AssignmentEventStatus.NotHandled && currentStatus !== status}
      data-highlighted={currentStatus === status}
      className={'p-button-secondary p-button-outlined'}
      style={currentStatus === status ? {borderColor: color} : undefined}
      css={commandsButtonCss}
      onPointerCancel={(e) => e.preventDefault()}
      onPointerDown={onPointerDown}>
      {icon(currentStatus !== AssignmentEventStatus.NotHandled && currentStatus !== status)}
      <div
        data-progress
        ref={progressRef}
        style={{transition: `height ${pressThreshold}ms linear, width ${pressThreshold}ms linear`}}
      />
      <div data-tooltip>{onHold ? locale.reactionDialog.holdPressed : label}</div>
    </Button>
  );
};
