import {CanvasLayersData, UnifiedEvent} from '../common';

type IAvailableLocales = 'Ru';

export type IAssignmentEventType = {
  id: string;
  type: 'AssignmentEventType';
  name: string;
  title: string;
  counting: boolean;
  resolutionRequired: boolean;
  icon?: {
    iconClass: string;
    color: string;
  };
  canvasLayers?: CanvasLayersData;
} & {
  [k in `locale${IAvailableLocales}`]?: Partial<IAssignmentEventTypeLocale>;
};

export type IAssignmentEventTypeLocale = {
  title: string;
  countTitle: string;
  countUnit: Array<[string, string]>;
};

export enum ObjectType {
  Car = 'Car',
  Bicycle = 'Bicycle',
  Person = 'Person',
  Roadsign = 'Roadsign',
  Motorcycle = 'Motorcycle',
  Animal = 'Animal'
}

export enum AreaType {
  Directed = 'Directed',
  Bidirectional = 'Bidirectional'
}

export enum AssignmentEventStatus {
  NotHandled = 'NotHandled',
  FalsePositive = 'FalsePositive',
  Handled = 'Handled',
  Alert = 'ViolationDetected'
}

export interface IAssignment {
  type: 'Assignment';
  id: string;
  title: string;
  entityId: string;
  entityType: string | 'Camera';
  assignmentTypeId: number;
  priorityId: string;
  parameters: IAssignmentParameters;
}

export interface IAssignmentParameters {
  a?: [number, number];
  b?: [number, number];
  points?: Array<[number, number]>;
  threshold?: number;
  areaType?: AreaType;
  typesForControl?: ObjectType[];
  sensorType?: 'Thor' | 'Thor-X';
  host?: string;
  user?: string;
  password?: string;
  connectionTimeout?: number;
  syncMode?: 'File' | 'All' | 'Metadata';
  stateHistoryTTL?: number;
  syncFrom?: string;
  syncTo?: string;
  useSectionSpeed?: boolean;
  mergeAssignmentId?: string;
  previewGenerationDelay?: number;
}

export interface IEntity {
  id: string | number;
  type: string;
}

export enum AssignmentEventType {
  MotionDetected = 'MotionDetected',
  NoMovement = 'NoMovement',
  CameraRotationDetected = 'CameraRotationDetected',
  SterileAreaEnter = 'SterileAreaEnter',
  SterileAreaLeave = 'SterileAreaLeave',
  BorderCrossed = 'BorderCrossed',
  DefocusDetected = 'DefocusDetected',
  DisappearObjectDetected = 'DisappearObjectDetected',
  AbandonedObjectsDetected = 'AbandonedObjectsDetected',
  VehicleTransitDetected = 'VehicleTransitDetected',
  ImageTooBlurryDetected = 'ImageTooBlurryDetected',
  ImageTooBlurryReset = 'ImageTooBlurryReset',
  ImageTooDarkDetected = 'ImageTooDarkDetected',
  ImageTooDarkReset = 'ImageTooDarkReset',
  ImageTooBrightDetected = 'ImageTooBrightDetected',
  ImageTooBrightReset = 'ImageTooBrightReset',
  GlobalSceneChangeDetected = 'GlobalSceneChangeDetected',
  GlobalSceneChangeReset = 'GlobalSceneChangeReset',
  SignalLossDetected = 'SignalLossDetected',
  SignalLossReset = 'SignalLossReset',
  MotionAlarmDetected = 'MotionAlarmDetected',
  MotionAlarmReset = 'MotionAlarmReset'
}

export interface IBaseAssignmentEvent extends IEntity {
  id: string;
  assignmentId: string;
  timestamp?: number;
  handlingTimeout?: number;
  eventType: AssignmentEventType;
  status: AssignmentEventStatus;
  bbox?: [number, number, number, number];
  location?: string | null;
  assignment?: {
    assignmentTypeId: number;
    assignmentTypeTitle: string;
    assignmentIconColor: null;
    assignmentIconClass: string;
    priorityIconClass: string;
    entityType: 'Camera';
    priorityIconColor: string;
    entityId: string;
    priorityTitle: string;
    priorityId: string;
    priorityValue: number;
    entityTitle: string;
    createdAt: string;
    title: string;
    status: string;
    parameters: IAssignmentParameters;
  };
}
export interface IAssignmentEvent extends IBaseAssignmentEvent {
  type: 'AssignmentEvent';
  entityId?: string;
  cameraId?: string;
  createdAt?: string;
  canNext?: boolean;
  canPrev?: boolean;
}

export interface ICubeAssignmentEvent extends Omit<IAssignmentEvent, 'assignment'> {
  assignmentTypeTitle: string;
  assignmentTypeIconClass: string;
  assignmentEventTypeName: string;
  assignment: string;
  assignmentParameters: string;
}

export const isAssignmentEvent = (event: UnifiedEvent): event is IAssignmentEvent => (
  Boolean('assignment' in event) || Boolean('assignmentEventTypeName' in event)
);
export const isAssignmentEventType = (event: IAssignmentEventType | {}): event is IAssignmentEventType =>
  Boolean('type' in event && event.type === 'AssignmentEventType');

export interface IAssignmentEventCounter extends IBaseAssignmentEvent {
  type: 'AssignmentEventCounter';
  count: number;
  assignment?: IBaseAssignmentEvent['assignment'] & {
    parameters: {
      typesForControl: ObjectType[];
    };
  };
}
export interface IMotionDetectorEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.MotionDetected | AssignmentEventType.NoMovement;
}
export interface ICameraRotationEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.CameraRotationDetected;
}
export interface ISterileAreaEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.SterileAreaEnter | AssignmentEventType.SterileAreaLeave;
  objectId: string;
}
export interface IBorderCrossEvent extends IAssignmentEventCounter {
  eventType: AssignmentEventType.BorderCrossed;
  direction: 'Direct' | 'Reverse';
}
export interface IDefocusEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.DefocusDetected;
}
export interface IDisappearObjectEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.DisappearObjectDetected;
}
export interface IAssignmentEventCounter extends IBaseAssignmentEvent {
  type: 'AssignmentEventCounter';
  count: number;
  assignment?: IBaseAssignmentEvent['assignment'] & {
    parameters: {
      typesForControl: ObjectType[];
    };
  };
}
export interface IMotionDetectorEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.MotionDetected | AssignmentEventType.NoMovement;
}
export interface ICameraRotationEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.CameraRotationDetected;
}
export interface ISterileAreaEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.SterileAreaEnter | AssignmentEventType.SterileAreaLeave;
  objectId: string;
}
export interface IBorderCrossEvent extends IAssignmentEventCounter {
  eventType: AssignmentEventType.BorderCrossed;
  direction: 'Direct' | 'Reverse';
}
export interface IDefocusEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.DefocusDetected;
}
export interface IDisappearObjectEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.DisappearObjectDetected;
}
export interface IAbandonedObjectsEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.AbandonedObjectsDetected;
}
export interface IVehicleTransitDetectedEvent extends Omit<IAssignmentEvent, 'location'> {
  eventType: AssignmentEventType.VehicleTransitDetected;
  location: string | null;
  vehicle: {
    plate: string | null;
    brand: string | null;
    model: string | null;
    speed: number | null;
    sectionSpeed: number | null;
    lane: string | null;
    direction: 'approaching' | 'receding' | null;
  };
  bboxes: Array<{
    bbox: [number, number, number, number];
    objectType: 'vehicle' | 'plate';
    pointsType: 'absolute' | 'normalized';
  }>;
}
export interface IImageTooBlurryDetectedEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.ImageTooBlurryDetected;
}
export interface IImageTooBlurryResetEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.ImageTooBlurryReset;
}
export interface IImageTooDarkDetectedEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.ImageTooDarkDetected;
}
export interface IImageTooDarkResetEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.ImageTooDarkReset;
}
export interface IImageTooBrightDetectedEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.ImageTooBrightDetected;
}
export interface IImageTooBrightResetEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.ImageTooBrightReset;
}
export interface IGlobalSceneChangeDetectedEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.GlobalSceneChangeDetected;
}
export interface IGlobalSceneChangeResetEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.GlobalSceneChangeReset;
}
export interface ISignalLossDetectedEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.SignalLossDetected;
}
export interface ISignalLossResetEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.SignalLossReset;
}
export interface IMotionAlarmDetectedEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.MotionAlarmDetected;
}
export interface IMotionAlarmResetEvent extends IAssignmentEvent {
  eventType: AssignmentEventType.MotionAlarmReset;
}

export {AssignmentPermission} from './AssignmentPermission';
