import {css} from '@emotion/react';

// language=SCSS
export const style = css`
  & {
    position: relative;
    display: block;
    background: black;
    overflow: hidden;
  }
  & > img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    pointer-events: none;
    transition: opacity ease 200ms 100ms;
  }
  & > img[src=''] {
    opacity: 0;
  }
  & > div[data-name='overlay'] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
  & > div[data-name='info'] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
  }
  & > div canvas {
    display: block;
  }
`;

export const buttonCss = css`
  position: absolute;
  left: 50%;
  bottom: calc(30rem / var(--bfs));
  transform: translateX(-50%);
`;

// language=SCSS
export const spinnerStyle = css`
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    width: 80px;
    height: 80px;
    border-radius: 30px;
    background: rgba(40, 40, 40, 0.5);
  }
`;
