import {createCamerasConnection} from '@netvision/lib-api-gateway';
import { EventsTypes } from '../models';

export function patchEventStatus(id: string, status: string, type: EventsTypes) {
  return createCamerasConnection().v2.appendEntityAttributes(
    {
      type,
      id,
      status
    },
    {
      keyValues: true
    }
  );
}
