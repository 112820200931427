import {css} from '@emotion/react';

// language=SCSS
export const $heading = css`
  & {
    font-size: var(--font-size-h2);
    color: var(--text-color);
    font-weight: 500;
    height: calc(var(--spacer-sm) * 2.5);
    margin: 0;
  }
`;

// language=SCSS
export const $fields = css`
  & {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: var(--font-size);
    font-weight: 500;

    margin-top: var(--spacer-sm);
    margin-bottom: calc(var(--spacer-sm) * -1);
    margin-right: calc(var(--spacer) * -1);
  }
  & > * {
    margin-bottom: calc(var(--spacer) / 2);
    margin-right: calc(var(--spacer));
  }
`;

// language=SCSS
export const $alignBottom = css`
  & {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

// language=SCSS
export const $entry = css`
  & {
    display: flex;
    align-items: center;

    > div:first-of-type {
      color: var(--text-color);
      margin-right: var(--spacer-xs);
      font-size: var(--font-size);
      font-weight: 500;
    }

    > div:last-of-type {
      color: var(--text-color-secondary);
      font-weight: 400;
      font-size: var(--font-size);
    }
  }
`;

// language=SCSS
export const $actionsPosition = css`
  & {
    position: absolute;
    top: var(--spacer);
    right: calc(var(--spacer) * 1.5);
  }
`;
