import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { IWidgetProps } from '../IWidgetProps';
import { EventStructure } from '../models';

type TWidgetPropsContextType = {
  children: ReactNode;
  props: IWidgetProps;
  event?: EventStructure & {location: string};
};

type TWidgetPropsContextProvider = {
  widgetProps: IWidgetProps;
  setWidgetProps: React.Dispatch<React.SetStateAction<IWidgetProps>>; 
}

const WidgetPropsContext = createContext<TWidgetPropsContextProvider>(null!);

export const useWidgetProps = () => useContext(WidgetPropsContext);

export const WidgetPropsProvider = ({ children, props, event }: TWidgetPropsContextType) => {
  const [widgetProps, setWidgetProps] = useState<IWidgetProps>(props);

  useEffect(() => {
    if (event && ('canPrev' in event || 'canNext' in event)) {
      setWidgetProps({
        ...props,
        props: {
          ...props.props,
          canPrev: Boolean(event.canPrev),
          canNext: Boolean(event.canNext)
        } as IWidgetProps['props']
      });
    }
  }, [])

  return (
    <WidgetPropsContext.Provider value={{ widgetProps, setWidgetProps }}>
      {children}
    </WidgetPropsContext.Provider>
  )
}
