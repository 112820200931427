import React, {FC, useEffect, useRef} from 'react';
import {useWidgetProps} from '../../hooks/useWidgetProps';
import {Widget} from '@netvision/lib-widget-renderer';
import {IWidgetProps} from '../../IWidgetProps';

type TboScheduleProps = {
  tboContainerAreaId: string;
  onHide: () => void;
};

export const TboScheduleAdapter: FC<TboScheduleProps> = ({tboContainerAreaId, onHide}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const {widgetProps: {mountChildren, areas}} = useWidgetProps();
  useEffect(() => {
    const node = ref.current;
    const schedule = getScheduleWidget(areas);
    if (!node || !schedule) return;
    return mountChildren(node, [
      {
        ...schedule,
        props: {
          ...schedule.props,
          tboContainerAreaId,
          onHide
        }
      }
    ]);
  }, [areas, mountChildren, onHide, tboContainerAreaId]);
  return <div ref={ref} />;
};

function getScheduleWidget(areas: IWidgetProps['areas']): Widget | null {
  if (!Array.isArray(areas)) return null;
  const widgetSchedule = areas.find((area) => area.name === 'schedule');
  if (!widgetSchedule || !widgetSchedule.children) return null;
  const [widget] = widgetSchedule.children;
  return widget as Widget;
}
