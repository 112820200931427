import React from 'react';
import {ApiRepositoryProvider} from './hooks/api/useApiRepository';
import {WidgetPropsProvider} from './hooks/useWidgetProps';
import {LocaleProvider} from './hooks/useLocale';
import {DetailsDialog} from './components/dialog';
import {LangProvider} from './providers/LangProvider';
import {EventTypesProvider} from './providers/EventTypesProvider';
import {ObserverProvider} from './hooks/useObserver';
import {NotificationSocketProvider} from './hooks/useNotificationSocket';
import {FetchEntityProvider} from './hooks/useEntity';
import {IWidgetProps} from './IWidgetProps';
import {EventsTypes, FieldsSettings, LocaleMetaData} from './models';
import {eventUnifier} from './eventUtils';

export type MetaDataType = {
  eventType: EventsTypes;
  tableSetting: FieldsSettings<any>;
  locale: LocaleMetaData;
};

export default function Root(widgetProps: IWidgetProps) {
  const { props: {
    event,
    onHide,
    onCleanQueue,
    containerId,
    eventBusID,
    eventBus,
    showActions = false
  } = {}} = widgetProps;
  return (
    <ApiRepositoryProvider lib={widgetProps?.props?.lib}>
      <WidgetPropsProvider props={widgetProps} event={event}>
        <LangProvider>
          <LocaleProvider>
            {!!event && typeof onHide === 'function' && (
              <NotificationSocketProvider>
                <FetchEntityProvider>
                  <EventTypesProvider>
                    <ObserverProvider data={{ eventBus, eventBusID }}>
                      <DetailsDialog
                        containerId={containerId}
                        event={eventUnifier(event)}
                        showActions={showActions}
                        onCleanQueue={onCleanQueue}
                      />
                    </ObserverProvider>
                  </EventTypesProvider>
                </FetchEntityProvider>
              </NotificationSocketProvider>
            )}
          </LocaleProvider>
        </LangProvider>
      </WidgetPropsProvider>
    </ApiRepositoryProvider>
  );
}
