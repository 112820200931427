/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {FC, useMemo} from 'react';
import {get} from 'lodash-es';
import {useEntity} from '../../hooks/useEntity';
import {RefField as RefFieldProps} from '../../models';
import {applyJoin} from '../../utils/applyJoin';

type Props<T extends {}> = {
  rowData: T;
  description: RefFieldProps
};

export const RefField = (props: Props<{}>) => {
  const {rowData, description} = props;
  const {idField, typeStatic, typeField, join = {fields: ['title']}, picture} = description;
  const id = useMemo(() => get(rowData, idField) ?? '', [rowData, idField]);

  const type = useMemo(() => {
    if (typeStatic) return typeStatic;
    return typeField ? id : '';
  }, [typeStatic, typeField, id]);

  const [loading, , entity] = useEntity(type, id);
  const text = useMemo(() => (entity ? applyJoin(entity, join) : ''), [entity, join]);

  if (loading) return null;
  return !text ? (
    <span>{'-'}</span>
  ) : (
    <span css={$refCss}>
      {entity && picture ? <ImageField object={entity} field={picture} /> : null}
      {text}
    </span>
  );
};

const $refCss = css`
  display: flex;
  align-items: center;
  > img {
    margin-right: var(--spacer-xs);
  }
`;

export const ImageField: FC<{object: object; field: string; className?: string}> = ({object, field, className}) => {
  const url = useMemo(() => decodeUrl(get(object, field)), [object, field]);
  return !url?.href ? null : <img className={className} src={url.href} alt={''} css={$css} />;
};

const $css = css`
  display: block;
  object-fit: contain;
  height: calc(30rem / var(--bfs));
  width: calc(30rem / var(--bfs));
`;

const decodeUrl = (v: unknown) => {
  if (typeof v !== 'string') return null;
  let href = decodeURI(v);
  let url;

  if (href.includes('%')) {
    href = decodeURIComponent(v);
  }

  try {
    let str = href;
    const index = str.indexOf('://');
    if (index !== -1) {
      str = `http${str.slice(index)}`;
    }
    url = new URL(str);
  } catch (e) {}

  return {href, url};
};
