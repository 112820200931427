/** @jsx jsx */
import {jsx} from '@emotion/react';
import {Widget} from '@netvision/lib-widget-renderer';
import {FC, memo, useEffect, useRef, useState} from 'react';
import {useWidgetProps} from '../../hooks/useWidgetProps';
import {once} from 'lodash-es';
import {getCanvasInitState, OverlayParams} from '../../components/canvasWidget';
import {IWidgetProps} from '../../IWidgetProps';
import {UnifiedEvent, isAssignmentEvent} from '../../models';

type UpdaterType = {
  update: Parameters<Exclude<Widget['onMount'], undefined>>[0];
};

export const ArchivePlayerAdapter: FC<{
  event: UnifiedEvent;
  height: number | string;
  skipPreview?: boolean;
}> = memo(({event, height, skipPreview = false}) => {
  const {widgetProps: {mountChildren, areas, props: {canvasStyles} = {}}} = useWidgetProps();
  const playerContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = playerContainerRef.current;
    const areasData = getAreasChildren(areas);

    if (!node || !areasData?.archive || !areasData?.canvas) return;

    const {archive, canvas} = areasData;
    let canceled = false;
    let unmountCanvas = () => {};
    const cameraId = isAssignmentEvent(event) ? (event.assignment?.entityId || event.cameraId) : event.cameraId;

    const canvasMount = {
      // when archive has mounted overlay container, it calls mount
      mount(overlayParams: OverlayParams) {
        unmountCanvas = once(
          mountChildren(overlayParams.overlayContainer, [
            {
              ...canvas,
              props: {
                ...canvas.props,
                overlay: overlayParams,
                initState: getCanvasInitState(event, canvasStyles || {}, {
                  x: overlayParams.naturalWidth,
                  y: overlayParams.naturalHeight
                })
              }
            }
          ])
        );
      },
      // when overlay container is about to be removed, archive calls unmount
      unmount() {
        unmountCanvas();
      }
    };

    const unmountArchive = mountChildren(node, [
      {
        ...archive,
        onMount: (update) => {
          if (canceled) return;
          setArchivePropsUpdater({update});
        },

        props: {
          ...archive.props,
          showEvents: false,
          skipPreview,
          cameraId,
          entity: {id: event.id, type: event.type},
          height,
          event,
          initialTime: event.timestamp,
          overlayMountHooks: canvasMount
        }
      }
    ]);

    return () => {
      canceled = true;
      unmountCanvas();
      unmountArchive();
    };
  }, [event]);

  const [archivePropsUpdater, setArchivePropsUpdater] = useState<UpdaterType | null>(null);

  useEffect(() => {
    if (!archivePropsUpdater) return;

    archivePropsUpdater.update((prevProps: {height: number; [k: string]: any}) => {
      return {
        ...prevProps,
        height
      };
    });
    
  }, [archivePropsUpdater, height]);

  return <div ref={playerContainerRef} />;
});

function getAreasChildren(areas: IWidgetProps['areas']) {
  if (!Array.isArray(areas)) return null;
  const widgetPlayer = areas.find((area) => area.name === 'player');
  const widgetCanvas = areas.find((area) => area.name === 'canvas');
  if (!widgetCanvas || !widgetCanvas.children) return null;
  if (!widgetPlayer || !widgetPlayer.children) return null;
  const [archive] = widgetPlayer.children;
  const [canvas] = widgetCanvas.children;

  return {
    archive,
    canvas
  } as {
    [key: string]: Widget;
  };
}
