import {IAssignmentEvent, ICubeAssignmentEvent, ITboEvent} from '.';
import {eventUnifier} from '../eventUtils';
export enum EventsTypes {
  AssignmentEvent = 'AssignmentEvent',
  TboEvent = 'TboEvent'
}

export enum EventType {
  AssignmentEvent = 'AssignmentEventType',
  TboEvent = 'TboEventType'
}

export type CanvasLayersData = {
  [key: string]: CanvasLayer;
};

export type CanvasLayer = {
  type: 'polygon' | 'line';
  path: string;
  pointsType: string;
  title: {
    template: string;
    position?: string;
  };
  styles: {
    [k: string]: string;
  };
};

export type EventStructure = IAssignmentEvent | ICubeAssignmentEvent | ITboEvent;

export type UnifiedEvent = ReturnType<typeof eventUnifier>;
