const actions = {
  Alert: 'Нарушение',
  Warn: 'Ложное срабатывание',
  Success: 'Обработано',
  holdPressed: 'Удерживайте кнопку!',
  success: 'Успешно',
  statusIsUpdating: 'Статус обновляется',
  error: 'Ошибка',
  couldNotUpdateStatus: 'Не удалось обновить статус',
  cleanQueue: 'Очистить всю очередь'
};

const reactionDialog = {
  alert: 'Тревога',
  falsePositive: 'Ложное',
  handled: 'Угрозы нет',
  assignment: 'Аналитика',
  camera: 'Камера',
  date: 'Дата',
  holdPressed: 'Удерживайте кнопку!'
};

const externalFilePreviewLocale = {
  previewIsNotReady: 'Превью кадр еще в обработке',
  previewIsNotFound: 'Превью кадр не найден',
  previewLoadingError: 'Не удалось загрузить превью'
};

const containerTypes = {
  COMMON: 'Обычный',
  MESH: 'Сетчатый',
  BUNKER: 'Бункер',
  UNKNOWN: 'Другой'
};

const archiveStatus = {
  notFound: 'Архив не найден',
  serverError: 'Ошибка соединения, пожалуйста, повторите попытку позже.'
}

const anaParamsLocale = {
  header: 'Параметры аналитики',
  buttonText: 'Закрыть',
  title: 'Данные по аналитике',
  textToOpen: 'Подробнее...'
}

export const defaultLocale = {
  reactionDialog,
  externalFilePreviewLocale,
  actions,
  archiveLabel: 'Архив',
  from: 'из',
  allowed: 'допустимых',
  containerTypes,
  archiveStatus,
  anaParamsLocale
};

export type Locale = typeof defaultLocale;
