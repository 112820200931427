import React from 'react';
import {get} from 'lodash-es';
import {
  DateTimeField,
  EnumField,
  RefArrayField,
  RefField,
  RefTransformField,
  ScheduleField,
  TextField,
  TextIconField,
  ObjectField,
  ModalField,
  TboContainersField,
} from '../components/fields';

import {
  isTextField,
  isTextIconField,
  isDateTimeField,
  isRefField,
  isRefArrayField,
  isRefTransformField,
  isEnumField,
  isScheduleField,
  isTboContainerField,
  FieldsType,
  EventsTypes,
  isObjectField,
  isModalField
} from '../models';

type GetFieldArgs<T, C> = {
  rowData: T
  cell: C
  field: string
  locales?: Record<string, string>
  entityType?: EventsTypes
}

export const getFieldByType = <T extends Record<string, any>, C extends FieldsType>({
  rowData,
  cell,
  field,
  locales,
  entityType
}: GetFieldArgs<T, C>) => {
  let value = get(rowData, field);

  if ('localization' in cell && locales) {
    value = get(locales, (cell.localization as Record<'path', string>).path)?.[value] as T[string]
  }

  // Base Cells
  if (isTextField(cell)) return <TextField text={value} />;
  if (isTextIconField(cell)) return <TextIconField data={rowData as any} cell={cell} />;
  if (isDateTimeField(cell)) return <DateTimeField timeStamp={value} />;
  if (isRefField(cell)) return <RefField rowData={rowData} description={cell} />;
  if (isRefArrayField(cell)) return <RefArrayField rowData={rowData} cell={cell} />;
  if (isRefTransformField(cell)) return <RefTransformField description={cell} rowData={rowData} />;
  if (isEnumField(cell)) return <EnumField cell={cell} value={value} />;
  if (isScheduleField(cell))
    return entityType && <ScheduleField rowData={rowData} eventType={entityType} field={field} cell={cell} />;
  if (isObjectField(cell)) return <ObjectField object={value} cell={cell}  />;
  if (isModalField(cell)) return <ModalField cell={cell}>{getFieldByType({rowData, cell: cell.cell, field, locales})}</ModalField>
  // Uniq Cells
  if (isTboContainerField(cell)) return <TboContainersField rowData={rowData as any} />;
  return null;
};
