import React, { FC, ReactNode, useContext } from 'react';
import { GlobalEventBus } from '@netvision/lib-types-frontend'

type TObserverContextType = {
  eventBus?: GlobalEventBus
  eventBusID?: string
}

const ObserverContext = React.createContext<TObserverContextType>(null!);

export const useObserver = () => useContext(ObserverContext)

export const ObserverProvider: FC<{ data: TObserverContextType, children: ReactNode }> = ({ data, children }) => {
  return (
    <ObserverContext.Provider value={data}>
      {children}
    </ObserverContext.Provider>
  )
}
