import {getFiwareHeaders} from '@netvision/lib-api-gateway';

// TODO вынести в lib-api-repo
export const fetchCameraSnapshot = (fileUrl: string, signal: AbortSignal): Promise<Blob | null> => {
  return fetch(fileUrl, {
    method: 'GET',
    signal,
    headers: getFiwareHeaders()
  }).then((res) => {
    if (res.status === 404) return null;

    const cType = res.headers.get('content-type');
    if (!cType) throw new Error('content-type is not defined');

    if (cType === 'video/mp4') {
      return res.blob() as unknown as Blob;
    }
    return null;
  });
};
