import React, {createContext, FC, useContext} from 'react';
import {useEntityMap} from '../hooks/useEntityMap';
import {IEntity} from '@netvision/lib-api-gateway';
import { useEventDataInfo } from '../hooks';

type IEntityListProvider<E extends IEntity> = {
  Provider: FC;
  useProvider: () => Map<string, E>;
};

export const createEntityMapProvider = <E extends IEntity>(
  getKey: (en: E) => string,
  refine?: (en: E) => E
): IEntityListProvider<E> => {

  const ctx = createContext<Map<string, E>>(null!);
  const Provider: IEntityListProvider<E>['Provider'] = ({children}) => {
    const {eventType} = useEventDataInfo()
    const eventTypes = useEntityMap<E>(eventType, getKey, refine);
    return <ctx.Provider value={eventTypes}>{children}</ctx.Provider>;
  };

  const useProvider = () => useContext(ctx);

  return {
    Provider,
    useProvider
  };
};
