import {css} from '@emotion/react';

// language=SCSS
export const $dialog = css`
  & {
    width: calc(100vw - 30rem / var(--bfs));
    height: calc(100vh - 30rem / var(--bfs));
    max-height: unset;
    max-width: unset;
    font-size: calc(14rem / var(--bfs));
  }
  body &.p-dialog > .p-dialog-content {
    height: 100%;
    font-size: calc(14rem / var(--bfs));
  }
`;
