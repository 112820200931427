/** @jsx jsx */
import {jsx} from '@emotion/react';
import {useCurrentTime} from '../../hooks/useCurrentTime';
import {FC} from 'react';

export const CountDown: FC<{
  className?: string;
  endTime: number;
}> = ({className, endTime}) => {
  const time = useCurrentTime(1000);
  const diff = endTime - time;
  let value = '';
  if (diff < 0) {
    value = '00:00';
  } else {
    const date = new Date(diff);
    value = `${twoDigits(date.getMinutes())}:${twoDigits(date.getSeconds())}`;
  }
  return <span className={className}>{value}</span>;
};

function twoDigits(number: number): string {
  if (number < 10) {
    return `0${number}`;
  } else {
    return `${number}`;
  }
}
