import {createEntityMapProvider} from './createEntityMapProvider';
import {IAssignmentEventType, ITboEventType} from '../models/';

const {Provider, useProvider} = createEntityMapProvider<IAssignmentEventType | ITboEventType>(
  (en) => {
    return en.name;
  },
  (en) => {
    if (en.icon?.color && en.icon.color.startsWith('--')) {
      en.icon.color = `var(${en.icon.color})`;
    }
    return en;
  }
);

export const EventTypesProvider = Provider;
export const useEventTypes = useProvider;
