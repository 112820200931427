import {css} from '@emotion/react';
// language=SCSS
export const commandsButtonCss = css`
  &.p-button.p-button-outlined {
    position: relative;
    padding: calc(8rem / var(--bfs)) calc(10rem / var(--bfs));

    overflow: visible;

    &:disabled {
      pointer-events: none;
      opacity: 1;
      filter: brightness(1);

      > * {
        filter: grayscale(1);
      }
    }

    &:not(:disabled):hover {
      background: transparent;
      border-color: var(--text-color);
    }

    &:not(:disabled):active {
      background: transparent;
      border-color: var(--text-color);
    }

    &[data-highlighted='true'] {
      pointer-events: none;
    }

    > span {
      display: none;
    }

    > [data-tooltip] {
      background: var(--surface-a);
      width: max-content;
      border-radius: var(--border-radius);
      padding: calc(10rem / var(--bfs)) calc(15rem / var(--bfs));

      position: absolute;
      top: calc(100% + 10rem / var(--bfs));
      left: 50%;
      transform: translateX(-50%);
    }

    > [data-tooltip] {
      opacity: 0;
      pointer-events: none;
      transition: opacity var(--transition-duration) ease;
    }

    &:hover > [data-tooltip] {
      opacity: 1;
    }

    > [data-progress] {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      background: rgba(var(--text-color-raw), 0.3);
      border-radius: var(--border-radius);
    }
  }
`;
