import React, {useCallback, useState} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import {ModalField as ModalFieldProps} from '../../models';

type Props = {
  cell: ModalFieldProps;
};

const wrapperStyles = css`
  & > span,
  & > div {
    min-width: 300px;
    min-height: 250px;
  }
`;
const linkToOpenStyles = css`
  text-decoration: underline;
  cursor: pointer;
`;

export const ModalField: React.FC<Props> = ({cell, children}) => {
  const {buttonText, title, textToOpen} = cell;
  const [isOpen, setIsOpen] = useState(false);
  const hideModal = useCallback(() => setIsOpen(false), []);
  const openModal = useCallback(() => setIsOpen(true), []);
  return (
    <>
      <span css={linkToOpenStyles} onClick={openModal}>{textToOpen}</span>
      {isOpen && (
        <Dialog
          closable={false}
          onHide={hideModal}
          visible={isOpen}
          header={title}
          footer={<Button onClick={hideModal} className="p-button-secondary p-button-outlined" label={buttonText} />}>
          <div className="p-mx-3 p-my-3" css={wrapperStyles}>
            {children}
          </div>
        </Dialog>
      )}
    </>
  );
};
