import React, {useCallback, useState} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Tooltip} from 'primereact/tooltip';
import {getFieldByType} from '../../helpers/getFieldByType';
import {ScheduleField, EventsTypes} from '../../models';
import {TboScheduleAdapter} from '../../widget-adapters';
import {get, noop} from 'lodash-es';

const tableScheduleByEvent = {
  [EventsTypes.TboEvent]: TboScheduleAdapter,
  [EventsTypes.AssignmentEvent]: null
};

type ScheduleCellAdapterProps = {rowData: any; eventType: EventsTypes; cell: ScheduleField; field: string};

export const ScheduleFieldAdapter = ({rowData, eventType, cell, field}: ScheduleCellAdapterProps) => {
  const Schedule = tableScheduleByEvent[eventType];
  const [open, setOpen] = useState(false);
  const onHide = useCallback(() => setOpen(false), []);
  const openSchedule = useCallback(() => setOpen(true), []);

  if (!Schedule) return null;

  const complexObjectId = get(rowData, field);

  return (
    <span role="button" css={$wrapper} onClick={complexObjectId ? openSchedule : noop}>
      {getFieldByType({
        rowData,
        cell: cell.label,
        field
      })}
      <Tooltip target=".tbo-complex-object-schedule" content="График вывоза мусора"></Tooltip>
      {complexObjectId && <i className="mdi mdi-18px mdi-launch tbo-complex-object-schedule" />}
      {open && <Schedule {...rowData} onHide={onHide} />}
    </span>
  );
};

const $wrapper = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  & > i {
    margin-left: var(--spacer-xs);
    margin-right: var(--spacer-xs);

    transition: opacity var(--transition-duration) ease;
  }

  tr & > i {
    opacity: 0;
  }

  tr:hover & > i {
    opacity: 1;
  }
`;
