import {useCallback, useEffect, useRef, useState} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const defaultState = {x: 0, y: 0, left: 0, top: 0, width: 0, height: 0, right: 0, bottom: 0};

export default function useMeasure<T extends HTMLElement>() {
  const elRef = useRef<T | null>(null);
  const [bounds, setBounds] = useState<typeof defaultState | null>(null);
  const [observer] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        setBounds(entry.contentRect);
      })
  );
  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);
  const ref = useCallback(
    (node: T | null) => {
      if (elRef.current !== node) {
        if (elRef.current) {
          observer.unobserve(elRef.current);
        }
        if (node) {
          observer.observe(node);
        } else {
          setBounds(null);
        }
        elRef.current = node;
      }
    },
    [observer]
  );
  return [ref, bounds] as const;
}
