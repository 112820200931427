import {css} from '@emotion/react';

// language=SCSS
export const containerCss = css`
  & {
    display: flex;
    align-items: center;

    > *:nth-child(1) {
      margin-right: calc(30rem / var(--bfs));
    }
  }
`;

// language=SCSS
export const countDownCss = css`
  & {
    display: flex;
    align-items: center;
    font-size: calc(14rem / var(--bfs));
    font-weight: 500;

    > *:nth-child(1) {
      margin-right: calc(10rem / var(--bfs));
    }
    > *:nth-child(2) {
      width: calc(50rem / var(--bfs));
      color: var(--text-color);
    }
  }
`;

// language=SCSS
export const buttonsCss = css`
  & {
    display: flex;
    align-items: center;
  }
  & > * {
    margin-right: calc(20rem / var(--bfs));
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
